<template>
  <nav ref="nav" :class="[drawer ? 'transform translate-x-0' : 'transform translate-x-full']">
    <header
      class="
        sticky
        top-0
        border-b border-gray-divide
        bg-white
        flex
        items-center
        justify-between
        px-4
        font-medium
        text-lg
        h-14
        text-gray-text_dark
        z-1000
      "
      style="width: inherit"
    >
      <slot name="header"></slot>
      <div @click="close_drawer" class="cursor-pointer">
        <svg-icon icon-class="cancel" className="gray-dark"></svg-icon>
      </div>
    </header>

    <main>
      <slot></slot>
    </main>

    <footer class="sticky bottom-0 h-14 border-t border-gray-divide bg-white flex items-center px-4 z-1000" style="width: inherit">
      <slot name="footer"></slot>
    </footer>
  </nav>
</template>

<script>
// 此組件不常用到，請局部引入
export default {
  computed: {
    drawer() {
      return this.$store.state.drawer
    },
  },
  data() {
    return {
      status: false,
    }
  },
  watch: {
    drawer(newVal) {
      if (newVal) {
        document.body.classList.add('overflow-hidden')
        setTimeout(() => {
          this.status = true
        }, 500)
      } else {
        document.body.classList.remove('overflow-hidden')
        this.status = false
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClick)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick)
  },
  methods: {
    // 關閉drawer選單
    close_drawer() {
      this.$store.commit('DRAWER_TOGGLER', {
        name: '',
      })
      this.$store.commit('OVERLAY_TOGGLER', false)
    },
    handleClick(e) {
      // 若點擊的元素不在nav之中，關閉drawer
      if (this.$refs.nav.contains(e.target)) return
      if (!this.status) return
      if (e.target.classList.value.includes('vs-select')) return
      if (e.target.classList.value.includes('overflow-hidden')) return
      this.close_drawer()
    },
  },
}
</script>

<style lang="scss" scoped>
nav {
  @apply bg-white text-sm z-3000 fixed
  transition-transform duration-500 ease-in-out
  right-0 rounded-l-xl overflow-hidden;

  width: 340px;

  height: 100%;
  top: 0;
  // top: calc(56px + 20px);
  // height: calc(100% - (56px + 20px));
  // box-shadow: -5px 10px 10px 3px rgba(209, 209, 209, 0.5);

  @media screen and (max-width: 500px) {
    width: 100%;
    // top: 56px;
    // height: calc(100% - 56px);
  }
}

main {
  @apply p-4 overflow-y-auto 
  overflow-x-hidden;

  height: calc(100% - 56px - 56px);
}
</style>
